import React from "react"
import styled from "styled-components"

// import app components
import FormContainer from "components/form"
import { Spacer, Edges } from "components"
import { colors } from "theme"

const Form = (props) => {
  const {
    backgroundColor,
    formType,
    formEmbedCode,
    formId,
    hideTitle,
    pt,
    pb,
    textColor,
    settings
  } = props

  return (
    <Container
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings?.contentLayout}>
          <StyledFormContainer
            formId={formId}
            formEmbedCode={formEmbedCode}
            formType={formType}
            hideTitle={hideTitle}
            {...props}
          />
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundColor, textColor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundColor || "transparent"};
  color: ${props => props.textColor || colors.alt};
`

const StyledFormContainer = styled(FormContainer)`
  margin-top: 40px;
  padding: 30px 10px;

  @media (min-width:768px) {
    padding: 30px 15px;
  }

  .MuiButtonBase-root {
    background: ${colors.secondary};
    color: #fff;
  }

  legend {
    font-size: 36px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.15;

    @media (min-width:768px) {
      font-size: 42px;
    }

    @media (min-width:768px) {
      font-size: 48px;
    }
  }

  .field-description {
    margin: 0;
    font-size: 12px;
    color: #616161;
  }
`

export default Form

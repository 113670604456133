import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"
import HamburgerMenu from "react-hamburger-menu"

// import app components
import { useStore } from "store"
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"
import * as theme from "theme"
import PlusIcon from "icons/plus.svg"
import MinusIcon from "icons/minus.svg"
import MenuIndentIcon from "icons/menu-indent-right.svg"

const MobileMenu = (props) => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  const items = useMenuItems("desktop-main-menu")

  const [activeItems, setActiveItems] = useState([])

  const handleArrowClick = (id) => {
    let newItems = [...activeItems]

    if (activeItems.includes(id)) {
      newItems = newItems.filter((i) => i !== id)
    } else {
      newItems.push(id)
    }

    setActiveItems(newItems)
  }

  const handleCloseMenu = () => dispatch({ type: "SET_MENU", payload: false })

  return (
    <Typography component="div">
      <Menu {...props} menuState={menu}>
        <HamburgerMenuContainer
          onClick={() => dispatch({ type: "TOGGLE_MENU" })}
        >
          <HamburgerMenu
            color={"#fff"}
            isOpen={menu}
            width={18}
            height={15}
            strokeWidth={1}
            menuClicked={() => ""}
          />
        </HamburgerMenuContainer>

        {items &&
          items.map(({ id, url, target, label, childItems, cssClasses }) => {
            const isActive = activeItems.includes(id)

            return (
              <React.Fragment key={id}>
                {childItems && childItems.nodes.length ? (
                  <>
                    {url === "#" ? (
                      <MenuLinkContainer
                        onClick={() => handleArrowClick(id)}
                        active={isActive}
                        children={Parser(label)}
                      >
                        <IconContainer>
                          {isActive ? <MinusIcon /> : <PlusIcon />}
                        </IconContainer>
                      </MenuLinkContainer>
                    ) : (
                      <MenuLinkContainer active={isActive}>
                        <MenuLink
                          as={target === "_blank" ? "a" : Link}
                          to={getUrlPath(url, target)}
                          href={getUrlPath(url, target)}
                          target={target !== null && target}
                          activeClassName="active"
                          onClick={handleCloseMenu}
                          children={Parser(label)}
                        />
                        <IconContainer onClick={() => handleArrowClick(id)}>
                          {isActive ? <MinusIcon /> : <PlusIcon />}
                        </IconContainer>
                      </MenuLinkContainer>
                    )}

                    <SubMenu
                      className={`sub-menu ${cssClasses.join(" ")}`}
                      active={isActive}
                    >
                      {childItems.nodes.map((o, i) => {
                        const isSubItemActive = activeItems.includes(o.id)

                        return o.childItems && o.childItems.nodes.length ? (
                          <React.Fragment key={i}>
                            {url === "#" ? (
                              <MenuLinkContainer
                                onClick={() => handleArrowClick(o.id)}
                                active={isSubItemActive}
                                children={Parser(o.label)}
                                className="sub-item"
                              >
                                <IconContainer className="sub-item">
                                  {isSubItemActive ? (
                                    <MinusIcon />
                                  ) : (
                                    <PlusIcon />
                                  )}
                                </IconContainer>
                              </MenuLinkContainer>
                            ) : (
                              <MenuLinkContainer
                                active={isSubItemActive}
                                className="sub-item"
                              >
                                <MenuLink
                                  as={o.target === "_blank" ? "a" : Link}
                                  to={getUrlPath(o.url, o.target)}
                                  href={getUrlPath(o.url, o.target)}
                                  target={o.target !== null && o.target}
                                  activeClassName="active"
                                  onClick={handleCloseMenu}
                                  children={Parser(o.label)}
                                />
                                <IconContainer
                                  onClick={() => handleArrowClick(o.id)}
                                  className="sub-item"
                                >
                                  {isSubItemActive ? (
                                    <MinusIcon />
                                  ) : (
                                    <PlusIcon />
                                  )}
                                </IconContainer>
                              </MenuLinkContainer>
                            )}

                            <SubMenu
                              className={`sub-menu ${cssClasses.join(" ")}`}
                              active={isSubItemActive}
                            >
                              {o.childItems.nodes.map((p, j) => {
                                return (
                                  <React.Fragment>
                                    <MenuLinkContainer
                                      key={j}
                                      active={isSubItemActive}
                                      className="sub-sub-item"
                                    >
                                      <MenuLink
                                        as={p.target === "_blank" ? "a" : Link}
                                        to={getUrlPath(p.url, p.target)}
                                        href={getUrlPath(p.url, p.target)}
                                        target={p.target !== null && p.target}
                                        activeClassName="active"
                                        onClick={handleCloseMenu}
                                        children={Parser(p.label)}
                                      />
                                    </MenuLinkContainer>
                                    <SubMenu
                                      className={`sub-menu ${cssClasses.join(
                                        " "
                                      )}`}
                                      active={isSubItemActive}
                                    >
                                      {p.childItems.nodes.map((q, k) => {
                                        return (
                                          <MenuLinkContainer
                                            key={k}
                                            active={isSubItemActive}
                                            className="sub-sub-sub-item"
                                          >
                                            <MenuLink
                                              as={
                                                q.target === "_blank"
                                                  ? "a"
                                                  : Link
                                              }
                                              to={getUrlPath(q.url, q.target)}
                                              href={getUrlPath(q.url, q.target)}
                                              target={
                                                q.target !== null && q.target
                                              }
                                              activeClassName="active"
                                              onClick={handleCloseMenu}
                                              children={
                                                <>
                                                  <MenuIndentIcon className="small-icon" />{" "}
                                                  {Parser(q.label)}
                                                </>
                                              }
                                            />
                                          </MenuLinkContainer>
                                        )
                                      })}
                                    </SubMenu>
                                  </React.Fragment>
                                )
                              })}
                            </SubMenu>
                          </React.Fragment>
                        ) : (
                          <MenuLinkContainer key={i} className="sub-item">
                            <MenuLink
                              as={o.target === "_blank" ? "a" : Link}
                              to={getUrlPath(o.url, o.target)}
                              href={getUrlPath(o.url, o.target)}
                              target={o.target !== null && o.target}
                              onClick={handleCloseMenu}
                              activeClassName="active"
                              className={cssClasses.join(" ")}
                              children={Parser(o.label)}
                            />
                          </MenuLinkContainer>
                        )
                      })}
                    </SubMenu>
                  </>
                ) : (
                  <MenuLinkContainer>
                    <MenuLink
                      as={target === "_blank" ? "a" : Link}
                      to={getUrlPath(url, target)}
                      href={getUrlPath(url, target)}
                      target={target !== null && target}
                      onClick={handleCloseMenu}
                      activeClassName="active"
                      className={cssClasses.join(" ")}
                      children={Parser(label)}
                    />
                  </MenuLinkContainer>
                )}
              </React.Fragment>
            )
          })}
      </Menu>

      <Gradient
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        menuState={menu}
      />
    </Typography>
  )
}

export default MobileMenu

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${(props) => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  max-width: 500px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.primary};
  overflow-y: auto;
`

const HamburgerMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  min-height: 75px;
  padding: 30px;
  margin-bottom: 2px;
  background: ${theme.colors.background.dark};
  cursor: pointer;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 22px;
  cursor: pointer;

  &.sub-item {
    svg {
      path {
        fill: #96a4ca;
      }
    }
  }
`

const ItemCSS = `
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 100%;
`

const MenuLinkContainer = styled.div`
  ${ItemCSS}
  margin-bottom: 2px;
  background: ${theme.colors.background.dark};

  ${({ active }) =>
    active &&
    css`
      background: ${theme.colors.secondary};

      > a.active {
        color: #fff;
      }
    `}

  &.sub-item {
    background: ${theme.colors.primary};
  }

  &.sub-sub-item {
    background: #101215;
  }
  &.sub-sub-sub-item {
    background: #191c21;
    a {
      justify-content: flex-start;
    }
  }
  .small-icon {
    color: #2c3745;
  }
`

const MenuLink = styled(Link)`
  ${ItemCSS}
  padding: 18px 10px 18px 20px;

  &.active {
    color: ${theme.colors.secondary};
  }
`

const SubMenu = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  width: 100%;
  background: ${theme.colors.background.dark};
`

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (!!props.menuState ? 1 : 0)};
  pointer-events: ${(props) => (!!props.menuState ? "all" : "none")};
  transition: ease all 0.2s;
`

import React, { useState } from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Widget } from "@typeform/embed-react"
import Parser from "html-react-parser"

// import app components
import * as theme from "theme"

const Form = (props) => {
  const {
    buttonText,
    formId,
    formEmbedCode,
    hideTitle,
    hiddenFields,
    formType,
    hidden,
    ...rest
  } = props

  const [form, setForm] = useState(null)
  const [success, setSuccess] = useState(null)

  const handleSuccess = (message) => {
    setSuccess(message)
  }

  const RenderForm = () => {
    if (formType === "typeForm") {
      return (
        <Widget
          id={formId}
          hideFooter={true}
          hideHeaders={true}
          opacity={100}
          inlineOnMobile={true}
          style={{
            height: "calc(100vh - 380px)",
            minHeight: "500px",
            width: "100%"
          }}
        />
      )
    } else if (formType === "airslate" || formType === "hubspot") {
      return Parser(formEmbedCode)
    } else {
        return (<p>Form type not found.</p>)
    }
  }

  return (
    <Container>
      <FormContainer component="div" {...rest}>
        <RenderForm />
      </FormContainer>
    </Container>
  )
}

export default Form

const Container = styled.div``

const p = { ...theme.mui.typography.body1 }

const FormContainer = styled(Typography)`
  position: relative;

  &.MuiTypography-root {
    margin-top: 0px;
    padding-top: 0px;
  }
`
